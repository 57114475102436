@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

html, body {
  max-width: 100%;
  overflow-x: hidden;
  background-color: #FBFBFB;
}

body {
  /* Do not make 100vh or newsfeed scroll breaks */
  background-color: #FBFBFB !important;
}
a.show-more-anchor {
  text-decoration: none;
}

.show-more-content {
  margin: 0;
  margin-top: 8px;
  font-family: Helvetica;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  display: block;
  -webkit-font-smoothing: antialiased;
}